import HouseDetailTrackingEvent from '../HouseDetailTracking';
import GoogleAnalyticsEvent from '../../../common/GoogleAnalyticsEvents';

export default class DetailBackButtonManager {
    static #instance: DetailBackButtonManager | null = null;

    public static getInstance(): DetailBackButtonManager {
        if (this.#instance === null) {
            this.#instance = new DetailBackButtonManager();
        }

        return this.#instance;
    }

    public static destroyInstance(): void {
        this.#instance = null;
    }

    #backButtons: HTMLAnchorElement[] = [];

    set backButtons(backButtons: HTMLAnchorElement[]) {
        this.#removeEventListeners();
        this.#backButtons = backButtons;
        this.#setEventListeners();
    }

    private constructor() {
        this.backButtons = Array.from(
            document.querySelectorAll<HTMLAnchorElement>('[data-role="detail-back-button"]')
        );
    }

    #removeEventListeners(): void {
        for (const backButton of this.#backButtons) {
            backButton.removeEventListener('click', this.#handleClick);
        }
    }

    #setEventListeners(): void {
        for (const backButton of this.#backButtons) {
            backButton.addEventListener('click', this.#handleClick);
        }
    }

    readonly #handleClick = (event: Event): void => {
        GoogleAnalyticsEvent.trackEvent(HouseDetailTrackingEvent.BACK_BUTTON_CLICK);
        if (window.history.length <= 1) {
            return;
        }
        event.preventDefault();

        window.history.back();
    };
}
