import TravelCompanyInput from '@naturehouse/design-system/components/atoms/travel-company-input/TravelCompanyInput';
import Modal from '../modal/Modal';
import '../../../modules/shared/components/AmountSelectorComponent';
import TravelPartyModalHandler from './TravelPartyModalHandler';
import TravelPartyModalFactory from './TravelPartyModalFactory';
// eslint-disable-next-line import/order
import { Observer } from '@naturehouse/nh-essentials/lib/architecture/ObserverPattern';

export default class TravelParty extends HTMLElement implements Observer {
    #modal: Modal | null = null;

    #travelPartyModalHandler: TravelPartyModalHandler | null = null;

    readonly #input = this.querySelector('nh-travel-company') as TravelCompanyInput;

    public update(event: CustomEvent): void {
        this.#input.value = JSON.stringify(event.detail);
    }

    public connectedCallback(): void {
        this.#input.addEventListener('click', this.#handleClick);
        this.#initialize();
    }

    public disconnectedCallback(): void {
        this.#input.removeEventListener('click', this.#handleClick);
    }

    readonly #handleClick = (): void => {
        this.#toggleModal(this.#modal as Modal);
    };

    readonly #initialize = (): void => {
        this.#modal = TravelPartyModalFactory.createModal({
            title: this.getAttribute('title') || '',
            maxPeople: this.getAttribute('max-people') || '0',
            contentTemplate: this.#loadTemplate('#travel-party-content'),
            footerTemplate: this.#loadTemplate('#travel-party-modal-footer')
        });

        document.body.append(this.#modal);
        this.#travelPartyModalHandler = new TravelPartyModalHandler(this.#modal);
        this.#travelPartyModalHandler.attach(this);
    };

    #loadTemplate(id: string): HTMLTemplateElement {
        const template = this.querySelector(id);
        if (!template) {
            throw new Error('Template not found');
        }

        return <HTMLTemplateElement>template;
    }

    #toggleModal(modal: Modal): void {
        if (modal.isOpen) {
            modal.close();
            return;
        }

        modal.open({
            bindToElement: this.#input
        });
    }
}

if (!customElements.get('travel-party')) {
    customElements.define('travel-party', TravelParty);
}
