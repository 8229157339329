import DateFromTo from '@naturehouse/design-system/components/atoms/date-from-to/DateFromTo';
import { Observer } from '@naturehouse/nh-essentials/lib/architecture/ObserverPattern';
import { formatToDateString } from '@naturehouse/nh-essentials/lib/dates/date';
import DatePickerCalendar, {
    DatePickerCalendarEvents,
    DatePickerCalendarSelectState
} from '../../../calendar/webComponents/DatePickerCalendar';
import ArrivalDatesCollection, {
    ArrivalDatesCollectionEndpointParams
} from './arrival-dates/ArrivalDatesCollection';
import ArrivalDatesStorageManager from './arrival-dates/ArrivalDatesStorageManager';

export enum DateFromToSelectDetail {
    START = 'start',
    END = 'end'
}

export default class ArrivalDatesToCalendarConnector implements Observer {
    readonly #houseId: string;

    #startDate: Date;

    #endDate: Date;

    readonly #datepickerCalendar: DatePickerCalendar = document.querySelector(
        'datepicker-calendar'
    ) as DatePickerCalendar;

    readonly #arrivalDepartureElement: DateFromTo = document.querySelector(
        'nh-date-from-to'
    ) as DateFromTo;

    public constructor({ startDate, endDate }: { startDate: Date | null; endDate: Date | null }) {
        const element: HTMLMetaElement = document.getElementById('house-id') as HTMLMetaElement;

        this.#houseId = element.content;

        this.#startDate = startDate ?? new Date();
        const newEndDate = endDate ?? this.#startDate;
        this.#endDate = new Date(newEndDate.getFullYear(), newEndDate.getMonth() + 4, 0);
    }

    public async initialize(): Promise<void> {
        const manager = ArrivalDatesStorageManager.getInstance();
        manager.attach(this);

        await manager.fetchInitialData(this.#getParams());

        this.#setEventListeners();
    }

    public update(event: CustomEvent): void {
        if (this.#datepickerCalendar.selectState === DatePickerCalendarSelectState.END) {
            return;
        }

        this.#datepickerCalendar.allowedDates = event.detail.data;
    }

    #setEventListeners(): void {
        this.#arrivalDepartureElement.addEventListener(
            'select',
            async (e: Event): Promise<void> => {
                const event = e as CustomEvent;

                if (event.detail !== DateFromToSelectDetail.START) {
                    return;
                }

                await this.#retrieveArrivalDates();
            }
        );

        this.#arrivalDepartureElement.addEventListener('cleared', this.#retrieveArrivalDates);

        this.#datepickerCalendar.addEventListener(
            DatePickerCalendarEvents.SHOW_MORE_MONTHS,
            async (event: Event): Promise<void> => {
                const customEvent = event as CustomEvent<{ extendDateRangeWithMonths: number }>;
                this.#extendDateRange(customEvent.detail.extendDateRangeWithMonths);
                await this.#retrieveArrivalDates();
            }
        );
    }

    #extendDateRange(extendDateRangeWithMonths: number): void {
        this.#startDate = new Date(this.#endDate);
        this.#endDate = new Date(
            this.#endDate.getFullYear(),
            this.#endDate.getMonth() + extendDateRangeWithMonths + 1,
            0
        );
    }

    readonly #retrieveArrivalDates = async (): Promise<void> => {
        await ArrivalDatesCollection.getInstance().retrieve(this.#getParams());
    };

    readonly #getParams = (): ArrivalDatesCollectionEndpointParams => ({
        houseId: this.#houseId,
        searchParams: new URLSearchParams({
            start_date: formatToDateString(this.#startDate),
            end_date: formatToDateString(this.#endDate)
        })
    });
}
